div.fc_card-container img{
	position: static !important;
}


/* === CARD CONTAINER === */
div.card-container {
	position: relative;
	height: 180px;

	display: block;
	padding: 0;
	margin: 0 0 20px 0;
	
	-webkit-perspective: 1000px;
	   -moz-perspective: 1000px;
	     -o-perspective: 1000px;
	    -ms-perspective: 1000px;
	        perspective: 1000px;

}

/* === CARD === */

.card-container .card {
	border-radius: 0px;

	width: 100%;
	height: 100%;
	position: absolute;

	display: inline-block;
	padding: 0;
	margin: 0;

	-webkit-transition: -webkit-transform .7s;
	 -moz-transition: -moz-transform .7s;
	   -o-transition: -o-transform .7s;
	  -ms-transition: -o-transform .7s;
	      transition: transform .7s;

	-webkit-transform-style: preserve-3d;
	   -moz-transform-style: preserve-3d;
	     -o-transform-style: preserve-3d;
	    -ms-transform-style: preserve-3d;
	        transform-style: preserve-3d;
}

/* === STYLE FOR THE FRONT & BACK SIDE === */

.card-container .card>div{	
	border-radius: 0px;
	
	height: 100%;
	width: 100%;
	position: absolute;
    text-align: center;
	
	margin: 0;

	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	    -ms-box-sizing:border-box;
	        box-sizing:border-box;


	-webkit-backface-visibility: hidden;
	   -moz-backface-visibility: hidden;
	     -o-backface-visibility: hidden;
	    -ms-backface-visibility: hidden;
	        backface-visibility: hidden;
}

/* === BEGGINING EFFECT === */

.card-container .card[data-direction="top"] .back, .card-container .card[data-direction="bottom"] .back{
	-webkit-transform: rotateX(180deg);
	   -moz-transform: rotateX(180deg);
	     -o-transform: rotateX(180deg);
	    -ms-transform: rotateX(180deg);
	        transform: rotateX(180deg);
}
.card-container .card[data-direction="right"] .back, .card-container .card[data-direction="left"] .back{
	-webkit-transform: rotateY(180deg);
	 -moz-transform: rotateY(180deg);
	   -o-transform: rotateY(180deg);
	  -ms-transform: rotateY(180deg);
	      transform: rotateY(180deg);
}

/* === EFFECT DIRECTIONS === */

.flipping-right {
	-webkit-transform: rotateY(180deg);
	 -moz-transform: rotateY(180deg);
	   -o-transform: rotateY(180deg);
	  -ms-transform: rotateY(180deg);
	      transform: rotateY(180deg);
}

.flipping-left {
	-webkit-transform: rotateY(-180deg);
	 -moz-transform: rotateY(-180deg);
	   -o-transform: rotateY(-180deg);
	  -ms-transform: rotateY(-180deg);
	      transform: rotateY(-180deg);
}

.flipping-top {
	-webkit-transform: rotateX(180deg);
	 -moz-transform: rotateX(180deg);
	   -o-transform: rotateX(180deg);
	  -ms-transform: rotateX(180deg);
	      transform: rotateX(180deg);
}

.flipping-bottom {
	-webkit-transform: rotateX(-180deg);
	 -moz-transform: rotateX(-180deg);
	   -o-transform: rotateX(-180deg);
	  -ms-transform: rotateX(-180deg);
	      transform: rotateX(-180deg);
}


/* SOME METRO COLOR THEMES */
	
	/* PURPLE */
	.purple div.card{
		color: white;
		border:0;
	}

	.purple div.card>div{
		background: #791869;
	}
	
	/* RED */
	.red div.card{
		color: white;
		border:0;
	}

	.red div.card>div{
		background:#C23B3D;
	}

	/* LIGHT-BLUE */
	.light-blue div.card{
		color: white;
		border:0;
	}

	.light-blue div.card>div{
		background:#00AFF0;
	}

	/* GREEN */
	.green div.card{
		color: white;
		border:0;
	}

	.green div.card>div{
		background:#88B500;
	}

	/* ORANGE */
	.orange div.card{
		color: white;
		border:0;
	}

	.orange div.card>div{
		background:#CF3F20;
	}

	/* BLUE */
	.blue div.card{
		color: white;
		border:0;
	}

	.blue div.card>div{
		background:#0C5CEA;
	}

	
	/* YELLOW */
	.yellow div.card{
		color: white;
		border:0;
	}

	.yellow div.card>div{
		background: #F27A00; 
	}


	.noCSS3Container{
		-webkit-perspective: none !important;
		   -moz-perspective: none !important;
			 -o-perspective: none !important;
		    -ms-perspective: none !important;
				perspective: none !important; 
	}

	.noCSS3Card{
		-webkit-transition: none !important;
		   -moz-transition: none !important;
		     -o-transition: none !important;
		    -ms-transition: none !important;
			    transition: none !important;

		-webkit-transform-style: none !important;
		   -moz-transform-style: none !important;
		     -o-transform-style: none !important;
		    -ms-transform-style: none !important;
		        transform-style: none !important;
	}

	.noCSS3Sides{
		-webkit-backface-visibility: visible !important;
		   -moz-backface-visibility: visible !important;
		     -o-backface-visibility: visible !important;
		    -ms-backface-visibility: visible !important;
		        backface-visibility: visible !important;

		-webkit-transform: none !important;
		   -moz-transform: none !important;
		     -o-transform: none !important;
		    -ms-transform: none !important;
		        transform: none !important;
	}

